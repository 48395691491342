import React from "react";
import { useTranslation } from "react-i18next";

import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';



const LanguageSwitcher = () => {

	const containerSize = {
		height: 25,
		display: "flex",
		mt: 1
	};
    const { i18n } = useTranslation();
   
	const [choice, setChoice] = React.useState(i18n.language);

	const handleChange = (_event, checked) => {
		setChoice(checked);
		i18n.changeLanguage(checked);
        localStorage.setItem('lang',checked)
	};


	return (

		<Box
			sx={containerSize}
		>
			<ToggleButtonGroup

				color="primary"
				value={choice}
				exclusive
				onChange={handleChange}
				aria-label="Platform"


			>
			
				<ToggleButton value="en">English</ToggleButton>
				<ToggleButton value="iu">ᐃᓄᒃᑎᑐᑦ</ToggleButton>

			</ToggleButtonGroup>
		</Box>

	);
}

export default LanguageSwitcher;
