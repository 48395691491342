import Typography from '@mui/material/Typography';
import visualLogo from "./logo.png";
const Logo = () => {

	return (

		<Typography variant="h3" noWrap component="a" href="/"
			sx={{
				mr: 2,
				display: { xs: 'none', md: 'flex' },
				fontFamily: 'sans-serif',
				fontWeight: 700,
				letterSpacing: '.3rem',
				color: 'inherit',
				textDecoration: 'none',
			}}
		>
			<img src={visualLogo} height='40' alt="" />
		</Typography>
	);
}
export default Logo;