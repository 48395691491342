export const getProfile = () => {
  return {
    user: {
      first: "",
      last: "",
      username: "",
      password: "",
      userId: 0,
      token: "",
    },
  };
};

export const getFilterNewest = () => {
  
  return {
  
      days :90,
      ascending:false,
      text:'',
      
  };
};

export const getFilterClosing = () => {
  
  return {
  
      days :90,
      ascending:true,
      text:'',
      
  };
};

export const getFilterOpen = () => {
  return {
	
	   days :3650,
       ascending:false,
       
   
  };
};

export {};
