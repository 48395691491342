import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login } from "../data/userService";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";

function Copyright(props) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{'Copyright © '}
			<Link color="inherit" href="https://inpp.ca/">
				INPP.ca
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}


async function checkWithDatabase(values) {

	const data = await login(values);
	//TODO handle error
	if (data) {
		try {
			let user = jwtDecode(data);
			localStorage.setItem('user', JSON.stringify(user));
			console.log('logging user ' + JSON.stringify(user));

			localStorage.setItem("task", '5');
			window.location = "/";


		} catch (err) {
			console.log('logging user error ' + err);
			// todo error 

		}

	} else {
		//TODO handle error
	}
}

const theme = createTheme();

export default function SignIn() {



	const handleSubmit = (event) => {
		event.preventDefault();
		const values = new FormData(event.currentTarget);


		let topass = {
			email: values.get('email'),
			// TODO crypt password

			password: values.get('password'),


		}
		checkWithDatabase(topass);

	};
	const { t } = useTranslation();

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						{t("signin")}
					</Typography>
					<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label={t("email")}
							name="email"
							autoComplete="email"
							autoFocus
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label={t("password")}
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						<FormControlLabel
							control={<Checkbox value="remember" color="primary" />}
							label={t("remember")}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							{t("signin")}
						</Button>
						<Grid container>
							<Grid item xs>
								<Link href="/signin" variant="body2">
									{t("forgot")}
								</Link>
							</Grid>
							<Grid item>
								<Link href="/signup" variant="body2">
									{t("gotosignup")}
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Copyright sx={{ mt: 8, mb: 4 }} />
			</Container>
		</ThemeProvider>
	);
}