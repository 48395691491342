import * as React from 'react';
import { useEffect } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import { Box, Typography } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from "react-i18next";
import { userLikeTender, userToogleTender } from "../data/userService";

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnCheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const ToogleWishList = (props) => {

	const containerSize = {
		height: 25,
		display: "flex",
		mt: 1
	};

	const { t } = useTranslation();

	const { tender } = props;

   let user ={id:0};
	
	let storedUser = JSON.parse(localStorage.getItem("user"));
	if(storedUser)
	   user = storedUser;
	   
	const [choice, setChoice] = React.useState(null);

	useEffect(() => {
		console.log("useeffect");
		const fetchData = async () => {
			
			if(user.id===0 ) return;
			
			const data = await userLikeTender(user.id, tender.id);
			if (data) {

				console.log("after await effect state" + JSON.stringify(data));
				if (data === true) {
					setChoice("true");
					return;
				}
			}
			setChoice("false");
		}

	fetchData()


	}, [tender, user, setChoice])


	async function getInitialStatus() {



		const data = await userLikeTender(user.id, tender.id);

		if (data) {
			console.log("result: from toggler init state" + JSON.stringify(data));

			return data;

		} else {

			return "false";
		}

	}


	async function getWantedStatus() {

		console.log("asking status");

		const data = await userLikeTender(user.id, tender.id);

		if (data) {
			console.log("result :" + data + ' tender ' + tender.id);
			return data;

		} else {

			return "false";
		}

	}


	async function setWantedStatus(status) {

		const data = await userToogleTender(user, tender, status);
		console.log("awaiting result for toogling :" + user.id + ' tender ' + tender.id + ' status' + status);
		if (data) {

			return data;

		} else {

			return false;
		}

	}


	function toogle(value) {

		/** send to db user list */
		setWantedStatus(value);



	}

	const handleChange = (_event, checked) => {
		setChoice(checked);
		toogle(checked);

	};


	return (

		<Box
		 display="flex"
  flexDirection="column"
  alignItems="center"
			sx={containerSize}
		>
			<Typography mr={3}>{t("mylist")}</Typography>
			<ToggleButtonGroup
				disabled={!user}
				color="primary"
				value={choice}
				exclusive
				onChange={handleChange}
				aria-label="Platform"
		

			>
				<ToggleButton disabled={user.id === 0} value="true">{t("added")}</ToggleButton>
				<ToggleButton disabled={user.id === 0} value="false">{t("removed")}</ToggleButton>

			</ToggleButtonGroup>
		</Box>

	);
}

export default ToogleWishList;
