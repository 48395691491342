import * as React from "react";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import pciLogo from "./PCI DEV.png";
import iscLogo from "./isc.png";


const Footer = () => {

	return (

		<Box sx={{ bgcolor: "#FFFFFF",height:65 }}
			    
				bgcolor='text.primary'
			>
		<Grid container  Spacing={2}>
			<Grid item xs={9} style={{ display: "flex", justifyContent: "flex-start" }} sx={{ ml: 2,  mt :2 ,mr: 3}}>
				<Box sx={{ bgcolor: "#FFFFFF",height:55 }}>
				<Typography fontSize={12}>Funded by </Typography>
				<img src={iscLogo} height='25' alt="" />
				</Box>
			</Grid>
			<Grid item xs={2} 
				style={{display: "flex", justifyContent: "flex-end " }}
				 sx={{    mt:2, display: { xs: 'none', md: 'block'} }}>
				
				<img src={pciLogo} height='25' alt="" />
			</Grid>
		</Grid>
		</Box>



	);
}

export default Footer;

