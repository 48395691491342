
function SignOut() {
 
  // remove user
  
  localStorage.removeItem("user");
  localStorage.setItem('task','0');
  window.location = "/";
  return null;
}
export default SignOut;
