import './App.css';
import React, { Component, useRef, useEffect } from "react";
import { Route, Switch, Redirect, useLocation, withRouter } from "react-router-dom";

import { Box } from "@mui/material";
import GA4React from "react-ga4";
import Footer from "./footer/footer";
import Top from "./menubar/Top";



import SignIn from "./subscriber/SignIn";
import SignOut from "./subscriber/SignOut";
import SignUp from "./subscriber/SignUp";
import Profile from "./subscriber/Profile";
import { getFilterClosing, getFilterNewest, getFilterOpen } from "./data/filters";
import { getEndPoint } from "./data/userService";
import http from "./data/httpService";
import TenderResult from "./result/TenderResults";
import './locales/i18n';






class App extends Component {

	constructor(props) {
		super(props);

		// the global state
		this.state = {
			// list of results tenders
			tenders: [],


			// filter for closing tenders
			filterClosing: getFilterClosing(),

			// filter for new tenders
			filterNew: getFilterNewest(),

			// filter for open search
			filterOpen: getFilterOpen(),

		};


	}
	
	componentDidMount() {
        
        
		GA4React.send({ hitType: "pageview", page: window.location.pathname });
		let mode = localStorage.getItem('nunavut');
		if (!mode)
			localStorage.setItem('nunavut', true);
		document.title = "INPP.ca Buy & Sell";
		let command = localStorage.getItem('task');
		if (!command) {
			command = "0";
			localStorage.setItem('task', command)
		}
		let query = localStorage.getItem('query');
		if (!query){
			query = 'nunavut';
			localStorage.setItem('query',query);
		} 
		if (window.location.pathname === '/') {
			switch (command) {

				case "0": this.fetchNewTenders(); break;
				case "1": this.fetchOpenSearch(query); break;
				case "2": this.fetchNewTenders(); break;
				case "3": this.fetchClosingTenders(); break;
				case "4": this.fetchAmendedTenders(); break;
				case "5": this.fetchUserTenders(); break;
			}
		}
	}



	/**
	* Get the newest tenders from backend
	*/
	async fetchNewTenders() {

		console.log("Tender newest resync");

		let query = getEndPoint("newest/");
		// put nunavutMode or from an event

		const { data } = await http.getWithParam(query, {
			params: {
				filter: this.state.filterNew,
				mode: localStorage.getItem('nunavut'),
			},
		});

		// test if data is valid
		localStorage.setItem('qte', data.length);
		this.setState({ tenders: data });

	}


	/**
	* Get the closing tenders from backend
	*/
	async fetchClosingTenders() {

		console.log("Tender closing resync");

		let query = getEndPoint("closing/");
		// put nunavutMode or from an event

		const { data } = await http.getWithParam(query, {
			params: {
				filter: this.state.filterClosing,
				mode: localStorage.getItem('nunavut'),
			},
		});

		// test if data is valid
		console.log("Tender Closing arrived");
		localStorage.setItem('qte', data.length);
		this.setState({ tenders: data });
	}


	/**
	* Get the closing tenders from backend
	*/
	async fetchAmendedTenders() {

		console.log("Tender amended resync");

		let query = getEndPoint("amended/");
		// put nunavutMode or from an event

		const { data } = await http.getWithParam(query, {
			params: {
				filter: this.state.filterClosing,
				mode: localStorage.getItem('nunavut'),
			},
		});
		localStorage.setItem('qte', data.length);
		// test if data is valid
		console.log("Tender amended arrived");

		this.setState({ tenders: data });
	}


	/**
	* Get the closing tenders from backend
	*/
	async fetchUserTenders() {

		console.log("Tender user before resync");

		let query = getEndPoint("mylist/");

		let user = JSON.parse(localStorage.getItem('user'));
		const { data } = await http.getWithParam(query, {
			params: {
				filter: this.state.filterClosing,
				mode: localStorage.getItem('nunavut'),
				user: user.id,
			},
		});
		localStorage.setItem('qte', data.length);
		// test if data is valid
		console.log("Tender user after resync" + data);

		this.setState({ tenders: data });
		if (window.location.pathname !== '/')
			window.location = "/";
	}


	/**
	* Get the open search tenders from backend
	*/
	async fetchOpenSearch(text) {

		let f = this.state.filterOpen;
		f.text = text;
		this.setState({ filterOpen: f });
		let query = getEndPoint("opensearch/");
		// put nunavutMode or from an event

		const { data } = await http.getWithParam(query, {
			params: {
				filter: this.state.filterOpen,
				mode: localStorage.getItem('nunavut'),
			},
		});
		console.log("Tender Open search :" + f.text);
		localStorage.setItem('qte', data.length);
		this.setState({ tenders: data });
	}


	render() {

		return (

			<>
				{/** main box for the whole page */}

				<Box
					sx={{
						flexDirection: "column", border: "1px solid blue"
					}}
				>
					{/** menubar box */}
					<Top />

					{/** main page display */}
					<Box >

						<Switch>

							<Route exact path="/signin" render={() => (<SignIn />)} />
							<Route exact path="/signup" render={() => (<SignUp />)} />
							<Route exact path="/" render={() => (<TenderResult tenders={this.state.tenders} />)} />
							<Route exact path="/signout" render={() => (<SignOut />)} />
							<Route exact path="/profile" render={() => (<Profile />)} />

						</Switch>


					</Box>

					{ /** padding to prevent footer overflow into content */}


					{/** footer trailing at bottom  */}
					<Box
						className="footer"
						width='100%'
						
					>
						<Footer />
					</Box>


				</Box>
			</>

		);
	}
}
export default withRouter(App);