import React from "react";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { useTranslation } from "react-i18next";
import TenderDescription from "./TenderDescription";

import PropTypes from 'prop-types';
import moment from "moment";

const TenderResult = (props) => {
  const {tenders ,user} = props;
  
  const { t } = useTranslation();
  rows = [];
  
 
  if (tenders && tenders.length) {
    tenders.map((tend) => createTenderData(tend));
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t("t_reference")}</TableCell>
            <TableCell align="left">{t("t_description")}</TableCell>
            <TableCell align="center">{t("t_category")}</TableCell>
            <TableCell align="right">{t("t_pub_date")}</TableCell>
            <TableCell align="right">{t("t_close_date")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TenderFullRow key={row.id} row={row} user ={user} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function createTenderData(tend) {
  if (!tend) return;
  
  let tenderRow = packageRow(
    tend.id,
    tend.reference_number,
    tend.title,
    tend.notice_type,
    tend.publication_date,
    tend.closing_date,
    tend.description,
    tend.contact,
    tend.gsin_unspsc,
    tend.end_user_entity
  );

  rows.push(tenderRow);
}

function packageRow(
  id,
  reference,
  title,
  notice_type,
  pub_date,
  close_date,
  description,
  contact,
  gsin,
  end_user_entity
) {
  return {
    id: id,
    refNumber: makeLink(reference),
    title: title,
    noticeType: notice_type,
    pubDate: pub_date,
    closeDate: close_date,
    description: description,
    contact: contact,
    gsin_un: gsin,
    end_user_entity: end_user_entity,
  };
}
function makeLink(refString) {

  const url =
    "https://canadabuys.canada.ca/en/tender-opportunities/tender-notice/" + refString;

  return (
    <div>
      <a href={url} rel="noopener noreferrer" target="_blank">
        {refString}
      </a>
    </div>
  );
}
	function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        p: 1,
        m: 1,
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}


const fixDate = (stringDate) => {
  var today = new Date(stringDate);
  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;

  return today;
};

const closingAt = (stringDate) => {
 
  return new moment(stringDate).format("YYYY-MM-DD , HH:mm");
  
};


Item.propTypes = {
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};


function TenderFullRow(props) {
  const { row, user } = props;
  const [open, setOpen] = React.useState(false);
  
  return (
  
    <React.Fragment>
     
     
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {" "}
          {row.refNumber}
        </TableCell>
        <TableCell align="left">{row.title}</TableCell>
        <TableCell align="left">{row.noticeType}</TableCell>
        <TableCell align="right"> {fixDate(row.pubDate)} </TableCell>
        <TableCell align="right"> {closingAt(row.closeDate)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            
             <TenderDescription tender={row} user={user}/>
           
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
let rows = [];

export default TenderResult;
