import * as React from 'react';

import { useTranslation } from "react-i18next";
import { createTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ToogleWishList from "./ToogleWishList";
import moment from "moment";

const TenderDescription = (props) => {

	const { tender,user } = props;

	const { t } = useTranslation();
	const theme = createTheme({
		palette: {
			primary: {
				// light: will be calculated from palette.primary.main,
				main: '##F0F0F0',
				// dark: will be calculated from palette.primary.main,
				// contrastText: will be calculated to contrast with palette.primary.main
			},
			secondary: {
				light: '#0066ff',
				main: '#0044ff',
				// dark: will be calculated from palette.secondary.main,
				contrastText: '#ffcc00',
			},
			// Provide every color token (light, main, dark, and contrastText) when using
			// custom colors for props in Material UI's components.
			// Then you will be able to use it like this: `<Button color="custom">`
			// (For TypeScript, you need to add module augmentation for the `custom` value)
			custom: {
				light: '#ffa726',
				main: '#f57c00',
				dark: '#ef6c00',
				contrastText: 'rgba(0, 0, 0, 0.87)',
			},
			// Used by `getContrastText()` to maximize the contrast between
			// the background and the text.
			contrastThreshold: 3,
			// Used by the functions below to shift a color's luminance by approximately
			// two indexes within its tonal palette.
			// E.g., shift from Red 500 to Red 300 or Red 700.
			tonalOffset: 0.2,
		},

	});
	
	const ClosingIn = (stringDate) => {
 
  let result = interval(new Date(stringDate));
  let sendBack = "";
  let max = 4;
  if (result.years > 0) {
    sendBack += result.years + "  "+t('year')+" ";
    max--;
  }
  if (result.months > 0) {
    sendBack += result.months + "  "+t('month')+" ";
    max--;
  }
  if (result.weeks > 0) {
    sendBack += result.weeks + "  "+t('week')+" ";
    max--;
  }
  if (result.days > 0) {
    sendBack += result.days + "  "+t('day')+" ";
    max--;
  }
  if (result.hours > 0 && max > 0) {
    sendBack += result.hours + "  "+t('hour')+" ";
    max--;
  }
  if (result.minutes > 0 && max > 0) {
    sendBack += result.minutes + "  "+t('minute')+" ";
  }

  return sendBack;
};
function interval(date1) {
	console.log(date1);
  let deadLine = moment(date1);
  let rightNow = moment(new Date());
  let r = {
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  r.years = deadLine.diff(rightNow, "years");
  if (r.years > 0) {
    deadLine = deadLine.subtract(r.years, "years");
    console.log("removing " + r.years);
  }


  r.days = deadLine.diff(rightNow, "days");
  if (r.days > 0) {
    deadLine = deadLine.subtract(r.days, "days");
  }
  r.hours = deadLine.diff(rightNow, "hours");
  if (r.hours > 0) {
    deadLine = deadLine.subtract(r.hours, "hours");
  }

  r.minutes = deadLine.diff(rightNow, "minutes");
  if (r.minutes > 0) {
    deadLine = deadLine.subtract(r.minutes, "minutes");
  }
  r.seconds = deadLine.diff(rightNow, "seconds");

  return r;
}

	function packgageTime(r) {
		

		let p = "  ";
		if (r.years > 0) p += r.years + " " + t('year') + " ";
		if (r.weeks > 0) p += r.weeks + " " + t('week') + " ";
		if (r.days > 0) p += r.days + " " + t('day') + " ";
		if (r.hours > 0) p += r.hours + " " + t('hour') + " ";
		if (r.minutes > 0) p += r.minutes + " " + t('minute') + " ";

		return r;
	}

	function Item(props) {
		const { sx, ...other } = props;
		return (
			<Box
				sx={{
					bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
					color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
					border: '1px solid',
					borderColor: (theme) =>
						theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
					p: 1,
					m: 1,
					borderRadius: 2,
					fontSize: '0.875rem',
					fontWeight: '700',
					...sx,
				}}
				{...other}
			/>
		);
	}

	Item.propTypes = {
		/**
		 * The system prop that allows defining system overrides as well as additional CSS styles.
		 */
		sx: PropTypes.oneOfType([
			PropTypes.arrayOf(
				PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
			),
			PropTypes.func,
			PropTypes.object,
		]),
	};


	return (

		<Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)' ,gridTemplateRows: 'repeat(2, 250px)',}}>
			<Item sx={{ gridRow: '1', gridColumn: '1' }}>
				<Typography flexGrow="1" textAlign="center">{t("closingin")}</Typography>
				<Typography  flexGrow="1" textAlign="center" mt={1}>{<strong>{packgageTime(ClosingIn(tender.closeDate))}</strong>}</Typography>
				{/** TODO hide if no user*/}
				<ToogleWishList tender={tender}  user={user}></ToogleWishList>
			</Item>
			
			<Item sx={{ gridRow: '1', gridColumn: '2/7' }} >
				<Typography ><strong>{t("description")}</strong></Typography>
				<Typography >{tender.description.substring(0,500)+ "  ..."}</Typography>
			</Item>

			


		</Box>


	);

}
export default TenderDescription;