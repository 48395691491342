import * as React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from "react-i18next";

const NunavutChoice = () => {

	const containerSize = {
		height: 25,
		display: "flex",
		mt: 1
	};

    const { t } = useTranslation();

	let selection = localStorage.getItem('nunavut' || 'true');

	const [choice, setChoice] = React.useState(getStartState(selection));

	function getStartState(select) {
		if (select === 'true') {
			return 'nunavut';
		}
		else { return 'all' }

	}

	function toogle(value) {

		if (value === 'nunavut') {
			localStorage.setItem('nunavut' ,true);
		} else {
			localStorage.setItem('nunavut' ,false);
		}



	}

	const handleChange = (_event, checked) => {
		setChoice(checked);
		toogle(checked);

	};


	return (

		<Box
			sx={containerSize}
		>
			<ToggleButtonGroup

				color="primary"
				value={choice}
				exclusive
				onChange={handleChange}
				aria-label="Platform"


			>
				<ToggleButton value="nunavut">{t("nunavut")}</ToggleButton>
				<ToggleButton value="all">{t("all")}</ToggleButton>

			</ToggleButtonGroup>
		</Box>

	);
}

export default NunavutChoice;
