import http from "./httpService";

import * as jsondata from "../config.json";


let config = jsondata;

/**
 * register user into database
 * @param {*} user
 * @returns the answer from the backend
 */
export async function register(user) {
	const { data } = await http.post(getEndPoint("register"), {
		
		param: {
			email: user.email,
			password: user.password,
			firstname: user.firstname,
			lastname: user.lastname,
			

		},
	});
	return data;
}


/**
 * send profile info to backend
 * @param {} user
 * @returns nothing
 */
export async function set_profile(user) {
	
	console.log('POST user to backend '+JSON.stringify(user));
	
	const { data } = await http.post(getEndPoint("profile"), {
		
		param: {
			id:user.id,
			cb1: user.cb1,
			cb2: user.cb2,
			cb3: user.cb3,
			cb4: user.cb4,
			firstname: user.firstname,
			lastname: user.lastname,
		},
	});

	return data;
}


/**
 * send login info to backend
 * @param {} user
 * @returns the user jwebtoken or the error code ( via http service)
 */
export async function login(userPayload) {

	console.log('payload : ' + userPayload);

	const { data } = await http.getWithParam(getEndPoint("login"), {
		params: {
			user: userPayload,
		},
	});

	return data;
}

/**
 * clear token then return to home page
 * @param {} user
 * @returns nothing
 */
export function logout() {
	// flush webtoken

	return;
}
/**
 * send login info to backend
 * @param {} user id
 * @param tender id
 * @returns if the tender is on user list
 */
export async function userLikeTender(user,tender) {

	

	const { data } = await http.getWithParam(getEndPoint("tendervsuser"), {
		params: {
			user: user,
			tender:tender
		},
	});
    console.log('after await ' + data.status);
	return data.status;
}

/**
 * send login info to backend
 * @param {} user id
 * @param tender id
 * @param status true or false
 * 
 * @returns done or not
 */
export async function userToogleTender(user,tender,status) {

	console.log('payload : ' + user.id + ' is toogle tender '+tender.id + 'with value '+ status);

	const { data } = await http.getWithParam(getEndPoint("tendertoggleuser"), {
		params: {
			user: user.id,
			tender:tender.id,
			status:status,
		},
	});
	
    console.log('finish aiwait with value '+ data);
	return status;
}



export function getEndPoint(sub) {

	return config.backend_dev + config.apiUrl + sub;

	if (process.env.NODE_ENV === "production") {
		console.log("using internal backend " + config.backend_dev + sub);

		return config.backend_dev + config.apiUrl + sub;
	} else {
		console.log("using Production backend " + config.backend_direct + sub);
		return config.backend_direct + config.apiUrl + sub;
	}
}
