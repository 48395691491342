import * as React from 'react';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { set_profile } from "../data/userService";


function Copyright(props) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{'Copyright © '}
			<Link color="inherit" href="https://inpp.ca/">
				INPP.ca
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const theme = createTheme();



const Profile = (props) => {
	
    const { t } = useTranslation();
	let user ={id:0};
	
	let storedUser = JSON.parse(localStorage.getItem("user"));
	if(storedUser){
	   user = storedUser;
	 }
    

	console.log("profile user :", JSON.stringify(user, null, 2));

	function handleChange(event ) {
		
		
		
		
	} 


	async function handleSubmit(event) {

		event.preventDefault();
		const values = new FormData(event.currentTarget);

		let topass = {
			id: user.id,
			cb1: values.get('wantnew') === null ? 'false' : 'true',
			cb2: values.get('wantclosing') === null ? 'false': 'true',
			cb3: values.get('wantamend') === null ? 'false' : 'true',
			cb4: values.get('wantsite') === null ? 'false' : 'true',
			firstname: values.get('firstname'),
			lastname: values.get('lastname'),

		}
		user.firstname= values.get('firstname');
		user.lastname=values.get('lastname');
		user.cb1= values.get('wantnew') === null ? "false":"true";
		user.cb2= values.get('wantclosing') === null ? "false":"true";
		user.cb3= values.get('wantamend') === null ? "false":"true";
		user.cb4= values.get('wantsite') === null ? "false":"true";
		
		
		
		localStorage.setItem("user",JSON.stringify(user));
		let answer = await set_profile(topass);
		if(answer === 'ok') {
		    localStorage.setItem("task",'5');
		    window.location = "/";
		}else{
			console.log('trouble setting profile ' + answer);
		}
	}


	

	return (
		
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="md">
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						{t("profile")}
					</Typography>
					<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="given-name"
									name="firstname"
									required
									defaultValue={user.firstname}
									fullWidth
									id="firstname"
									label={t("firstname")}
									autoFocus
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									required
									fullWidth
									defaultValue={user.lastname}
									id="lastname"
									label={t("lastname")}
									name="lastname"
									autoComplete="family-name"
								/>
							</Grid>

							<Grid item xs={12}>

								<FormControlLabel

									control={<Checkbox value="wantnew" defaultChecked={user.cb1 === 'true' ? true : false} onChange ={handleChange} name='wantnew' id='wantnew' color="primary" />}
									label={t("wantnew")}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={<Checkbox value="wantclosing" defaultChecked={user.cb2 === 'true' ? true : false} name='wantclosing' id='wantclosing' color="primary" />}
									label={t("wantclosing")}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={<Checkbox value="wantamend" defaultChecked={user.cb3 === 'true' ? true : false} name='wantamend' id='wantamend' color="primary" />}
									label={t("wantamend")}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={<Checkbox value="wantsite" defaultChecked={user.cb4 === 'true' ? true : false} name='wantsite' id='wantsite' color="primary" />}
									label={t("wantsite")}
								/>
							</Grid>

						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							{t("submit")}
						</Button>

					</Box>
				</Box>
				<Copyright sx={{ mt: 5 }} />
			</Container>
		</ThemeProvider>
	);
}

export default Profile;
