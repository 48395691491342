import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Avatar from '@mui/material/Avatar'

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';

import AccountCircle from '@mui/icons-material/AccountCircle';

import LogoutIcon from '@mui/icons-material/Logout';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import Logo from "./Logo";

import LoginIcon from '@mui/icons-material/Login';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import SearchBlock from './SearchBlock';
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import NunavutChoice from "./NunavutChoice";

const MainBar = () => {

		
	const [anchorEl, setAnchorEl] = React.useState(null);
	
	
	const isMenuOpen = Boolean(anchorEl);
	const { t } = useTranslation();
	var user = { id: 0 };
	var haveUser = localStorage.getItem('user') === null ? false : true;

	if (haveUser) {
		user = JSON.parse(localStorage.getItem('user'));
	}


	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	
	const handleMenuClose = (event) => {

		let value = 0;
		setAnchorEl(null);
		switch (event.currentTarget.value) {
			case 1: {
				 value = haveUser ? 1 : 2;
				break;
			}
			case 2: {
				 value = haveUser ? 3 : 4;
				// handled by component
				break;
			}
			default: {
				return;
			}
		}



		switch (value) {
			case 1: {
				// profile menu
				if (window.location.pathname !== '/profile') window.location = '/profile';

				break;
			}
			case 2: {
				// sign in menu
				if (window.location.pathname !== '/signin') window.location = '/signin';
				break;
			}
			case 3: {
				// logout menu
				window.location = '/signout'
				break;
			}
			case 4: {
				// subscribe menu
				window.location = '/signup';
				break;
			}
			default:
		}



	};
	
	const [anchorLeft, setAnchorLeft] = React.useState(null);
    const isLeftMenuOpen = Boolean(anchorLeft);
    const leftMenuId = 'left-menu-fx';
	
	
	
	
	const handleLeftMenuOpen = (event) => { setAnchorLeft(event.currentTarget); };

	const handleLeftMenuClose = (event) => {
		switch (event.currentTarget.value) {
			case 1: {
				// handled by component
				break;
			}
			case 2: {
				// handled by component
				break;
			}

			case 3: {
				localStorage.setItem('task','0');
				window.location = "/";
				
				break;
			}
			case 4: {
				localStorage.setItem('task','3');
				window.location = "/";
				
				break;
			}
			case 5: {
				localStorage.setItem('task','4');
				window.location = "/";
				
				break;
			}
			case 6: {
				localStorage.setItem('task','5');
				window.location = "/";
				
				break;
			}

			default: {
				// do nothing
			}
		}
		setAnchorLeft(null);
	};

	
	function RenderLeftMenu() {
		return (
			<Drawer
				anchor={'left'}

				id={leftMenuId}
				keepMounted

				open={isLeftMenuOpen}
				onClose={handleLeftMenuClose}
			>
				<MenuItem value="1" onClick={handleLeftMenuClose}><NunavutChoice/></MenuItem>
				<MenuItem value="2" onClick={handleLeftMenuClose}><LanguageSwitcher /></MenuItem>

				<Divider />
				<SearchBlock small={'block'} medium={'block'} />
				
				<Divider  />
				
				<MenuItem value="3" onClick={handleLeftMenuClose}><Typography >{t("tender_new")}</Typography></MenuItem>
				<MenuItem value="4" onClick={handleLeftMenuClose}><Typography>{t("tender_closing")}</Typography></MenuItem>
				<MenuItem value="5" onClick={handleLeftMenuClose}><Typography>{t("tender_updated")}</Typography></MenuItem>
				{haveUser ? <><Divider /> <RenderLeftMenuLogged /></> : <> </>}
	           

			</Drawer>
		)
	}

	function RenderLeftMenuLogged() {

		return (<MenuItem value="6" onClick={handleLeftMenuClose}><Typography >{t("tender_mylist")}</Typography></MenuItem>);

	}

	
	
	const menuId = 'primary-search-account-menu';


	function RenderUserMenu(){
	return(
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem value='1' onClick={handleMenuClose}>
				<IconButton
					size="large"
					aria-label="account of current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					{haveUser ? <AccountCircle /> : <LoginIcon />}
				</IconButton>
				{haveUser ? <p>Profile</p> : <p>Sign In</p>}
			</MenuItem>

			<MenuItem value='2' onClick={handleMenuClose}>
				<IconButton
					size="large"
					aria-label="logout current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					{haveUser ? <LogoutIcon /> : <SubscriptionsIcon />}
				</IconButton>
				{haveUser ? <p>Logout</p> : <p>Sign Up</p>}
			</MenuItem>

		</Menu>
	);
}
	

	function GetAvatar() {
		let name = user.firstname[0] + user.lastname[0];
		name = name.toUpperCase();
		return (name);
	}
	function ActiveTask() {
		let task = localStorage.getItem('task')
		let query = localStorage.getItem('query')
		if (!query) {
			query = '';
		}
		if (!task) {
			task = '0';
		}
		let qte = localStorage.getItem('qte')
		let padding = qte;
		if (!qte) {
			padding='';
			
		}
		else {
			
			padding= '  '+ qte + ' found';
		}

		if (window.location.pathname === '/') {
			switch (task) {

				case "0": return 'Newest' +padding
				case "1": return 'Search ' + query+padding
				case "2": return 'Newest'+padding
				case "3": return 'Closing'+padding
				case "4": return 'Amended'+padding
				case "5": return 'MyList'+padding
				default: return ('')
			}
		}



		return ('')
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static" sx={{ bgcolor: "#666666" }} >
				<Toolbar>
					<IconButton
								size="large"
								edge="start"
								color="inherit"

								aria-label="left menu"
								aria-controls={leftMenuId}
								aria-haspopup="true"
								onClick={handleLeftMenuOpen}

								sx={{ mr: 3 }}
							>
								<MenuIcon  />
							</IconButton>

					<Typography
						variant="h2"
						noWrap
						component="div"
						sx={{ display: { xs: 'none', sm: 'block' } }}
					>
						<Logo mt={2} />
					</Typography>
					
					<SearchBlock small={'none'} medium={'block'} />

					<Typography
						variant="h6"
						noWrap
						component="div"
						sx={{ display: { xs: 'block', sm: 'block',  fontSize: 15}}}
						
					>
							<ActiveTask />
					  </Typography>

				

				<Box sx={{ flexGrow: 1 }} />
				<Box sx={{ display: { xs: 'block', md: 'flex' } }}>

					<IconButton
						size="large"
						edge="end"
						aria-label="account of current user"
						aria-controls={menuId}
						aria-haspopup="true"
						onClick={handleProfileMenuOpen}
						color="inherit"
					>
						{haveUser ? <Avatar
							sx={{ bgcolor: haveUser ? '#F6FAFF' : 'gray' , color:'black'}}
							variant="contained"

						> <GetAvatar /> </Avatar> : <Avatar />}

					</IconButton>
				</Box>

			</Toolbar>
			<RenderLeftMenu />
			<RenderUserMenu />
		</AppBar>
	
		</Box >
	);

}
export default MainBar;